/**
 * Инициализация `ajax` метода плагина `magnific-popup`
 * @see  {@link http://dimsemenov.com/plugins/magnific-popup/documentation.html#ajax-type}
 *
 * @sourcecode wHTML:mfpAjax
 * @memberof   wHTML
 * @requires   {@link wHTML.inputMask }
 * @requires   {@link wHTML.tableWrapper }
 * @requires   {@link wHTML.viewTextImages }
 * @requires   {@link wHTML.viewTextMedia }
 * @requires   {@link wHTML.formValidation }
 * @param      {string}    [selector='.js-mfp-ajax'] - пользовательский css селектор для поиска и инита
 * @return     {undefined}
 */
wHTML.prototype.mfpAjax = function(selector) {
	selector = selector || '.js-mfp-ajax';

	$('body').on( 'click', selector, function(evt) {
		// TODO Код отправки аналитики диблируется, это не логично. Переделать файл forms.php. Дима 28.04
		try {
			var value = JSON.parse(evt.currentTarget.dataset.param).form.formId;
		} catch (evt) {
			console.log(evt);
		}

		if ((window.ga === undefined) || (!value)) return false;
		console.log('Выполнена строка: ga("send","event","show-popup","'+value+'")');
		ga('send','event','show-popup',value);
	});


	$('body').magnificPopup({
		type: 'ajax',
		delegate: selector,
		removalDelay: 300,
		mainClass: 'zoom-in',
		callbacks: {

			elementParse: function(item) {
				var itemData = item.el.data('param') || {};
				var itemUrl = item.el.data('url');
				var itemType = item.el.data('type') || 'POST';

				this.st.ajax.settings = {
					url: itemUrl,
					type: itemType.toUpperCase(),
					data: itemData
				};
			},

			ajaxContentAdded: function() {
				var $content = this.content || [];
				if ( $content.length ) {
					// _self.inputMask( $content );
					_self.tableWrapper( $content );
					_self.viewTextImages( $content );
					_self.viewTextMedia( $content );
				}

				_self.formValidation( $content );
			},
            close: function(){
                if ($('.js-auto-click-move').length){
                    $.ajax({
                        url: '/ajax/subscribecookieset',
                        method: 'post',
                        dataType: 'json',
                        data: {
                            'status': 2
                        },
                        success: function(response){
                            $('.js-auto-click-move').remove();
                        }
                    });
                }
            },
		}
	});
};
