/**
 * Событие, после успешной валидации формы и отправки данных.
 *
 * @sourcecode wHTML:formValidationAfterSubmit
 * @memberof   wHTML
 * @event      wHTML#formValidationAfterSubmit
 * @param      {Element}   $form - текущая форма, `jQuery element`
 * @param      {Object}    response - ответ по текущей форме
 * @return     {undefined}
 */
wHTML.prototype.formValidationAfterSubmit = function( $form, response ) {

	// пример очистки
	if ( response.resetForm ) {
		this.formValidationReset( $form );
	}

	// пример закрытия магнифика
	if ( response.closePopup ) {
		var mfpInstance = ( $.magnificPopup && $.magnificPopup.instance ) || {};
		if ( mfpInstance.isOpen ) {
			$.magnificPopup.close();
		}
	}

    if (response.removeelem) {
        $('#'+response.removeelem).remove();
    }
	console.info( 'HTML => Форма отправлена', response );
};